<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div class="parent align-center">
          <div class="container-upload">
            <h1 class="ricotta mb-6">
              Новое мероприятие
            </h1>
            <div class="mb-4 font">
              <r-input
                :value="null"
                label="Название"
                v-model="items['title']"
              />
              <r-input
                :value="null"
                class=" mt-4"
                label="Адрес или ориентир"
                v-model="items['address']"
                @keyup="onCoordinates(items['address'])"
                beforeIcon="search"
              >
              </r-input>
              <div class="culture__map-cont mt-4">
                <div class="culture__map">
                  <RirMapLeaflet
                    :searchIn="false"
                    v-if="upload"
                    :control-color="false"
                    :center="coordinates"
                  >
                    <l-marker
                      v-for="marker in markers"
                      :key="marker.id"
                      :visible="marker.visible"
                      :draggable="marker.draggable"
                      :lat-lng.sync="marker.position"
                    >
                      <l-icon
                        :icon-url="$markerIconTwo.imageHref"
                      />
                    </l-marker>
                  </RirMapLeaflet>
                </div>
              </div>
            </div>
            <r-date-picker
              label="Дата"
              class="mt-5"
              v-model="date"
              @input="onClickDate"
              :is-input="false"
              :selected="cleaningDates"
              :error="!cleaningDates.length"
            />
            <div class="h-250">
              <div
                class="selected-dates"
                v-if="!!formatDates.length"
              >
                <div
                  class="selected-dates__badge"
                  v-for="(date, index) in formatDates"
                  :key="index"
                >
                  {{ date }}

                  <button
                    class="selected-dates__clear"
                    @click="clearDate(index)"
                  >
                    <r-icon
                      icon="close"
                      fill="amelie"
                      size="16"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="grid-time mt-5 mb-5">
              <r-time-picker
                v-model="timeTo"
                class="mr-6"
                label="Время c"
                :disabled="false"
              />
              <r-time-picker
                v-model="timeFrom"
                label="Время по"
                :disabled="false"
              />
            </div>
            <r-textarea
              class="mb-6"
              value=""
              label="Описание"
              :rows="6"
              :max-rows="6"
              v-model="items['comment']"
            />
            <upload-file-input
              :count-file="1"
              upload-url="/uploadfile/index.php"
              :value="filesUpload"
              :accept-file="['image/png', 'image/jpeg', 'image/gif']"
              @input="uploadFile"
              sub-title="или перетяните сюда файл объёмом не более 50 МБайт"
            />
            <r-textarea
              class="mb-6"
              value=""
              label="Запрос на инвентарь"
              :rows="3"
              :max-rows="6"
              v-model="items['equipmentReply']"
            />
            <r-textarea
              class="mb-6"
              value=""
              label="Запрос на спецтранспорт или механизмы"
              :rows="3"
              :max-rows="6"
              v-model="items['specialVehicleReply']"
            />
            <div
              class="flex mt-2 mb-4"
              style="width: 100%;display: inline-block;"
            >
              <r-button
                class="flex-1"
                @click="save()"
                :disabled="cleaningDates.length == 0 || !items['title'] || !items['address']"
                title="Предложить"
                width="wide"
              >

              </r-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="uploadEnd"
      class="upload"
    >
      <div>
        <div @click="uploadEnd = !uploadEnd">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div class="parentEnd align-centerEnd">
          <div class="container-uploadEnd">
            <r-icon
              class="mr-2  mb-4"
              icon="selected"
              fill="matrix"
              size="56"
            />
            <h1 class="ricotta mb-2">
              Предложение отправлено
            </h1>
            <div class="mb-6 font">
              Предложение о новом мероприятии отправлено на модерацию. В ближайшее время мы
              ознакомимся с ним и разместим на сайте.
            </div>
            <div
              class="flex mt-2 delete__button delete__float"
              @click="clickEnd"
            >
              <r-button class="flex-1"
                          title="Отлично"
                          width="wide">
              </r-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="uploadAuto"
      class="upload"
    >
      <div>
        <div @click="uploadAuto = !uploadAuto">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div class="parentEnd align-centerEnd">
          <div class="container-uploadEnd">
            <h1 class="ricotta mb-2">
              Если автомобиль был эвакуирован
            </h1>
            <h3 class="ricotta-h mt-4">
              {{ $store.state.evacuatorHint.evacuatorHint }}
            </h3>
            <div class="mt-3 font">
              Возьмите с собой следующие документы:
            </div>
            <div class="mt-3 font">
              <ul>
                <li class="ml-8">
                  Паспорт
                </li>
                <li class="ml-8 mt-2">
                  Водительское удостоверение
                </li>
                <li class="ml-8 mt-2">
                  Свидетельство о регистрации ТС
                </li>
                <li class="ml-8 mt-2">
                  Страховой полис ОСАГО
                </li>
              </ul>
            </div>
            <!--<div class="mt-3 font">
              Если документы остались в автомобиле, то заберите их на штрафстоянке
              по адресу <br>ул. 8-ая Заводская. Тел. для связи +7(989)-700-20-36.
            </div> -->
            <div
              class="flex mt-8 delete__button delete__float"
              @click="clickEnd"
            >
              <r-button
                class="flex-1 mb-8"
                title="Понятно"
                width="wide"
                type="secondary"
              >
              </r-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import axios from 'axios';
import UploadFileInput from "@/components/UploadFileInput.vue";
import RirMapLeaflet from "@/components/RirMapLeaflet.vue";
import Api from "@/api/Api";
import { LMap, LTileLayer, LMarker, LPolyline, LIcon, LPopup } from "vue2-leaflet";
import L from "leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
Vue.use(vClickOutside);

export default {
  name: 'Upload',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LIcon,
    LPopup,
    RirMapLeaflet,
    UploadFileInput
  },
  data() {
    return {
      calendarDate: null,
      cleaningDates: [],
      mark: true,
      markers: [],
      image: 0,
      time: false,
      timeTo: '00:00',
      timeFrom: '00:00',
      upload: false,
      uploadEnd: false,
      uploadAuto: false,
      coordinates: [],
      items: [],
      filesUpload: [],
      date: null
    };
  },
  computed: {
    formatDates() {
      return this.cleaningDates.map(date => {
        const nDate = new Date(date);
        return nDate.toLocaleDateString('RU-ru');
      });
    }
  },
  created() {
    let _this = this;
    _this.coordinates = _this.$cityCenter;
    const newMarker = {
      position: {'lat': _this.coordinates[0], 'lng': _this.coordinates[1]},
      draggable: true,
      visible: true,
    };
    if(this.markers.length == 0) {
      this.markers.push(newMarker);
    }
    this.items.photo0 = '';
  },
  methods: {
    clearDate(index) {
      this.cleaningDates.splice(index, 1);
      this.isSave = false;
    },
    onClickDate(date) {
      if (!date) return;

      const index = this.cleaningDates.findIndex(el => el === date);
      this.isSave = false;
      if (index !== -1) {
        this.cleaningDates.splice(index, 1);
      } else {
        this.cleaningDates.push(date);
      }
    },
    formattedFiles(files) {
      const formattedFiles = [];
      console.log(files)
      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            type: el.type,
            name: el.name, // .split('.')[0]
            size: el?.size,
            icon: false,
            url: el.url
          }));
          formattedFiles.push(...fileList);
        } else {
          formattedFiles.push(el);
        }
      });
      return formattedFiles;
    },
    uploadFile(files) {
      let _this = this;
      if (!files?.length) {
        _this.filesUpload = [];
        _this.items.photo0 = null;
        return;
      }
      _this.filesUpload = _this.formattedFiles(files);
      _this.items.photo0 = _this.filesUpload[0].url;
    },
    deletePhoto() {
      this.items.photo0 = '';
      this.image++;
    },
    clickEnd() {
      this.upload = false;
      this.uploadEnd = false;
      this.uploadAuto = false;
    },
    uploadContainer() {
      this.upload = true;
    },
    uploadContainerAuto() {
      this.uploadAuto = true;
    },
    async onDragMarker(e) {
      const api = new Api();
      const geo = await api.getGeoSearch({lat: this.markers[0].position.lat, lng: this.markers[0].position.lng});

      const {address} = geo;
      this.mark = true;
      if (address) this.items.address = address;
    },
    async onCoordinates(item) {
      const api = new Api();
      const geo = await api.getGeoSearch({address: item.trim()});

      const {lat, lng} = geo;
      this.mark = false;
      this.markers[0].position = [lat, lng];
      this.mark = true;
    },
    save() {
      const v = this;

      const item = {
        id: 0,
        title: v.items.title,
        eventForm: {
          address: v.items.address,
          datesExtra: v.cleaningDates,
          date: v.items.date,
          time: `${v.timeTo} - ${v.timeFrom}`,
          equipment: '',
          specialVehicle: '',

          equipmentReply: v.items.equipmentReply,
          equipmentStatus: 'approved',
          equipmentFiles: '',

          specialVehicleReply: v.items.specialVehicleReply,
          specialVehicleStatus: 'approved',
          specialVehicleFiles: ''
        },

        lat: v.markers[0].position.lat,
        lng: v.markers[0].position.lng,
        photo0: v.items.photo0,
        photo1: '',
        photo2: '',
        comment: v.items.comment,
        commentResult: '',
        photoResult0: '',
        photoResult1: '',
        photoResult2: '',
        approved: 0,
        eventStatus: 1
      };

      this.$store.dispatch('loadSaveNew', item).then(result => {
        v.upload = false;
        v.uploadEnd = true;
      }, error => {
        console.error(error);
      });
    }
  },
  watch: {
    markers: {
      deep: true,
      handler(val) {
        this.onDragMarker();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.video {

}

.align-center {
  align-items: center;
  float: left;
  margin-top: 5px;
}

.align-centerEnd {
  align-items: center;
  float: left;
  margin-top: 5px;

}

.upload {
  background-color: white;
  z-index: 100000000;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}

.upload::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.upload::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.upload::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 20%;
  margin-left: 31%;
  margin-top: -150px;
  @media(max-width: 599px) {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    padding: 0 2.5vw;
  }
}

.container-upload {
  width: 87%;
  height: 300px;
  @media(max-width: 599px) {
    width: 100%
  }
}

.parentEnd {
  display: block;
  position: absolute;
  width: 70%;
  height: 400px;
  top: 50%;
  margin-left: 28%;
  margin-top: -180px;
}

.container-uploadEnd {
  width: 55%;
  height: 300px;
}

.margin-right {
  float: right;
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.font {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

.culture {
  max-width: 100%;
  flex-direction: column-reverse;

  &__cards {
    width: 100%;
  }

  &__map-cont {
    position: relative;
    top: unset;
    height: unset;
    padding: 0;
    margin-left: unset;
    width: 100%;
  }

  &__map {
    height: 160px;
    margin-bottom: 32px;
  }

  &__map--collapsed {
    height: 112px;
  }

  &__map-collapse-btn {
    height: 40px;
    width: 174px;
    border-radius: 8px;
    padding: 10px 16px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 87px);
    background-color: var(--rir-arrival);
    cursor: pointer;
    display: block;

    :first-child {
      color: var(--rir-godfather);
      border-bottom: 2px dashed #214eb052;
    }
  }

}

.hours {
  display: table;
  background: #FFFFFF;
  box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  border-radius: 8px;
  margin-top: 60px;
  z-index: 1200;
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  text-align: center;
  height: 160px;
  position: absolute;
  width: 300px;
  margin-top: 0px;

  .hour {
    margin-top: 5px;
    max-height: 150px;
  }

  .minutes {
    margin-top: 5px;
    max-height: 150px;
  }

  .hour::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .hour::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .hour::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .minutes::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .minutes::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .minutes::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .time-hover {
    width: 60px;
    margin: auto;
    margin-top: 6px;
  }

  .time-hover:hover {
    width: 60px;
    background: #e4edfb;
    margin: auto;
    color: #3D75E4;
    border-radius: 5px;
    margin-top: 6px;
  }
}

.clear {
  height: 56px;
  background: #3D75E4;
  opacity: 0.08;
  border-radius: 8px;
}

@media (max-width: 500px) {
  .mobile_column {
    flex-direction: column;

    .video__login.mr-6 {
      margin-bottom: 24px;
      margin-right: 0 !important;
    }
  }
}
.rir-date-picker__time.no_date{
  position: static;
  left: unset;
  top: unset;
}
.rir-date-picker__time .hour>span, .rir-date-picker__time .minutes>span{
  width: auto;
}
::v-deep textarea:focus{
  background: transparent!important;
}
.rir-date-picker__time_wrapper,
.rir-date-picker__time{
  width: 100%;
}


.selected-dates {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &__container {
    font-size: 12px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    padding: 4px 8px;
    border-radius: 50px;
  }

  &__badge {
    padding: 4px 8px;
    border-radius: 30px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    &:hover .selected-dates__clear {
      opacity: 1;
      visibility: visible;
    }
  }

  &__clear {
    position: absolute;
    top: 0;
    right: 0;
    align-self: stretch;
    height: 100%;
    width: 40%;
    background-image: linear-gradient(90deg, transparent, var(--rir-alien) 70%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    cursor: pointer;
  }
}

.grid-time {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
<style>
.RTextarea {
  background-color: #eaebed !important;
}
.RPopover__content {
  z-index: 100000000021 !important;
}
.RDatePicker__input {
  background-color: #eaebed !important;
  border-radius: 8px;
}
</style>
